<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="topsubmenu auto">
          <a-radio-group default-value="1" size="small" @change="changeSubmenu" button-style="solid">
            <a-radio-button value="1">
              应用管理
            </a-radio-button>
            <a-radio-button value="2">
              调用统计
            </a-radio-button>
            <a-radio-button value="3">
              接口统计
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="content-title auto">
          <div class="coustom-title fl">应用管理（{{total}}个）</div>
        </div>
          
        <div class="coustom-title-mes auto">
          <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />系统一共有应用 {{total}} 个
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addRoles"> 添加应用 </a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" :scroll="{ x: 1500 }">
            <div slot="authorization" slot-scope="text">
              <a-tag v-for="(item, index) in text" :key="index" style="margin: 2px 2px 2px 0;color:#9d9fa4;" v-show="(item.tags || item.scopes || item.children) && item.show">{{item.name}}</a-tag>
            </div>
            <div slot="enabled" slot-scope="text, record, index1">
              <a-switch v-model="tabledata[index1]['enabled']" size="small" @change="onChange(record)" />
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="editHandle(record)">编辑</a-button>
              <a-button size="small" @click="dataHandle(record)" class="m-r-5 bgblue borblue colorfff">统计</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteHandle(record)"
              >
                <a-button type="primary" size="small" class="bgred borred">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addRoles"> 添加应用 </a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      tablecolumns: [
        {
          title: '应用名称',
          dataIndex: 'name',
          width: 120
        },
        {
          title: 'AppID',
          dataIndex: 'appid',
          width: 200
        },
        {
          title: 'AppSECRET',
          dataIndex: 'appsecret',
          width: 180
        },
        {
          title: '接口权限',
          dataIndex: 'authorization',
          scopedSlots: { customRender: 'authorization' },
          width: 200
        },
        {
          title: '创建时间',
          dataIndex: 'createdDate',
          width: 200
        },
        {
          title: '最后修改人',
          dataIndex: 'lastModifiedBy',
          width: 150
        },
        {
          title: '最后修改时间',
          dataIndex: 'lastModifiedDate',
          width: 200
        },
        {
          title: '状态',
          dataIndex: 'enabled',
          fixed: 'right',
          scopedSlots: { customRender: 'enabled' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '205px',
              }
            }
          }
        }
      ],
      tabledata: [],
      total: 0,
      filter: {
        current_page: 1,
        page_size: 10
      },
      // value: [{
      //   id: '000000',
      //   order: 0,
      //   parentId: null,
      //   children: [
      //     {
      //       id: '010000',
      //       order: 0,
      //       parentId: '000000',
      //       children: [
      //         {
      //           id: '010100',
      //           order: 0,
      //           parentId: '010000'
      //         },
      //         {
      //           id: '010200',
      //           order: 1,
      //           parentId: '010000'
      //         }
      //       ]
      //     },
      //     {
      //       id: '020000',
      //       order: 1,
      //       parentId: '000000',
      //       children: [
      //         {
      //           id: '020100',
      //           order: 0,
      //           parentId: '020000'
      //         },
      //         {
      //           id: '020200',
      //           order: 1,
      //           parentId: '020000'
      //         }
      //       ]
      //     } 
      //   ]
      // }]
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/interface/index');
          break;
        case 2:
          this.$router.push('/system/interface/statistics');
          break;
        case 3:
          this.$router.push('/system/interface/api');
          break;
        default:
          break;
      }
    },
    async onChange (row) {
      let form = JSON.parse(JSON.stringify(row))
      form['enabled'] = row.enabled ? 1 : 0
      let res = await System.changeApp(row.id, form)
      if (!res['code']) {
        this.$message.success('修改成功！')
        this.getList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    dataHandle (row) {
      this.$router.push({
        path: '/system/interface/add',
        query: {
          type: 'data',
          id: row.id,
          appid: row.appid
        }
      })
    },
    editHandle (row) {
      this.$router.push({
        path: '/system/interface/add',
        query: {
          type: 'edit',
          id: row.id,
          appid: row.appid
        }
      })
    },
    addRoles () {
      this.$router.push({
        path: '/system/interface/add',
        query: {
          type: 'add'
        }
      })
    },
    async deleteHandle (row) {
      let res = await System.deleteApp(row.id)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    trunEnabled (data) {
      let tabledata = []
      data.map(item => {
        let newItem = JSON.parse(JSON.stringify(item))
        newItem['enabled'] = newItem['enabled'] ? true : false
        for (let key in newItem['authorization']) {
          let status = false
          for (let k in newItem['authorization'][key]['children']) {
            if (newItem['authorization'][key]['children'][k]['selected']) {
              status = true
              break
            }
          }
          if (status) {
            newItem['authorization'][key]['show'] = true
          }
        }
        tabledata.push(newItem)
      })
      return tabledata
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getList()
    },
    async getList () {
      this.loading = true
      let res = await System.getApp(this.filter)
      this.loading = false
      if (!res['code']) {
        this.tabledata = this.trunEnabled(res['data'])
        // console.log(this.tabledata)
        this.total = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getList()
  },
  mounted () {
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.add-btn {
  padding-bottom: 10px;
}
.add-btn, .add-item {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.add-item {
  text-align: center;
  padding: 10px 0;
}

.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}
</style>
